@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* JQuery adds unneeded filter tags that break transparency. Let's make ours important.
    This could break fade out, but who cares... */

  .veil .shadow {
    background: url(about:blank);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000) !important;
    zoom: 1;
  }

  .notice, .bar .button, .bar .button .image {
    zoom: 1;
  }

  .bar .background-rgba-fix {
    background: url(about:blank);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#80000000,endColorstr=#80000000);
    zoom: 1;
  }

  .bar .button.toggle {
    background: url(about:blank);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#9C000000,endColorstr=#9C000000);
    zoom: 1;
  }

  .bar .button.toggle:hover {
    background: none !important;
  }

  /*
  .bar .button.explicit-hover {
    background: url(about:blank) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#9C000000,endColorstr=#9C000000);
    zoom: 1;
  }*/

  .dialog, .popup .content {
    background: url(about:blank);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000) !important;
    zoom: 1;
  }

  /* I don't know why, but everything is effed on the maintenance page. Do a cheap fix for now. */
  .notice.maintenance .body {
    background: url(about:blank);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000) !important;
    zoom: 1;
  }

  .notice.maintenance .background-rgba-fix {
    display: none;
  }

  .notice.maintenance h1 {
    background: none;
  }

  .item .move-grip {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  }
}
